/* src/index.css */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  font-family: Inter, sans-serif;
}

@layer utilities {
  /* hide scrollbar for Chrome, safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* hide scrollbar for IE, Edge, and FireFox */
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* Target the scrollbar track in Chrome, Safari, etc. */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Customize background color */
}

/* Target the scrollbar thumb in Chrome, Safari, etc. */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Customize thumb color */
  height: 6px; /* Adjust thumb height */
  border-radius: 6px; /* Add rounded corners */
}

/* Target the scrollbar track in Firefox */
::-moz-scrollbar-track {
  background-color: #f1f1f1;
}

/* Target the scrollbar thumb in Firefox */
::-moz-scrollbar-thumb {
  background-color: #888;
  height: 6px;
  border-radius: 6px;
}

/* Target the scrollbar track in Internet Explorer 11 */
::-ms-scrollbar-track {
  background-color: #f1f1f1;
}

/* Target the scrollbar thumb in Internet Explorer 11 */
::-ms-scrollbar-thumb {
  background-color: #888;
  height: 6px;
  border-radius: 6px;
}

/* Global styles */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
  }
}
